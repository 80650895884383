import React from "react"
import { ReactComponent as CheckmarkIcon } from "@images/icons/checkmark-circle.svg"
import { ReactComponent as NoCheckmarkIcon } from "@images/icons/no-checkmark-circle.svg"

export const KLIPS_BUSINESS_BASE_HEADER = {
  heading: "Base",
  monthlyPrice: 90,
  annualPrice: 80,
  klips: true,
}

export const KLIPS_BUSINESS_GROW_HEADER = {
  heading: "Grow",
  monthlyPrice: 190,
  annualPrice: 170,
  klips: true,
}

export const KLIPS_BUSINESS_TEAM_HEADER = {
  heading: "Team",
  monthlyPrice: 350,
  annualPrice: 310,
  klips: true,
}

export const KLIPS_BUSINESS_TEAMPLUS_HEADER = {
  heading: "Team+",
  monthlyPrice: 690,
  annualPrice: 600,
  klips: true,
}

export const KLIPS_BUSINESS_COMPARISON = [
  { value: "Dashboards" },
  { value: "Users" },
  {
    value: "Data Refresh Rate",
  },
  {
    value: "Data Integrations",
  },
  {
    value: "Data Modelling + Join",
  },
  {
    value: "Published Links",
    hover:
      "Published links enable others to view your dashboards, either as a public URL (available via Search) or with access to a unique, non-indexable URL and, optionally, a password.",
  },
  { value: "PDF Reports" },
  {
    value: "Scheduled Reports",
    hover:
      "Email a snapshot of a dashboard to share with others as a one-time event or according to a schedule. Content can be shared as a PDF or as an image.",
  },
  {
    value: "Template Gallery",
    hover:
      "A collection of pre-built templates to help you get started quickly.",
  },
  { value: "Add Your Logo" },
  { value: "Single Sign On (SSO)" },
  { value: "Warm Up Refresh", hover: "Set a daily time for all of your data sources to refresh." },
  { value: "Priority Support" },
  {
    value: "Custom Onboarding",
    hover:
      "Get six hours of custom onboarding within your first three months, performed by our Professional Services team.",
  },

]

export const KLIPS_BUSINESS_BASE = [
  { value: "3" },
  { value: "Unlimited" },
  { value: "4hr" },
  { value: "130+" },
  { value: <CheckmarkIcon /> },
  { value: <NoCheckmarkIcon /> },
  { value: <CheckmarkIcon /> },
  { value: <NoCheckmarkIcon /> },
  { value: <CheckmarkIcon /> },
  { value: <CheckmarkIcon /> },
  { value: <NoCheckmarkIcon />, hideMobile: true },
  { value: <NoCheckmarkIcon />, hideMobile: true },
  { value: <NoCheckmarkIcon />, hideMobile: true },
  { value: <NoCheckmarkIcon />, hideMobile: true },
]

export const KLIPS_BUSINESS_GROW = [
  { value: "10" },
  { value: "Unlimited" },
  { value: "1hr" },
  { value: "130+" },
  { value: <CheckmarkIcon /> },
  { value: <CheckmarkIcon /> },
  { value: <CheckmarkIcon /> },
  { value: <CheckmarkIcon /> },
  { value: <CheckmarkIcon /> },
  { value: <CheckmarkIcon /> },
  { value: <NoCheckmarkIcon />, hideMobile: true },
  { value: <NoCheckmarkIcon />, hideMobile: true },
  { value: <NoCheckmarkIcon />, hideMobile: true },
  { value: <NoCheckmarkIcon />, hideMobile: true },
]

export const KLIPS_BUSINESS_TEAM = [
  { value: "20" },
  { value: "Unlimited" },
  { value: "15min" },
  { value: "130+" },
  { value: <CheckmarkIcon /> },
  { value: <CheckmarkIcon /> },
  { value: <CheckmarkIcon /> },
  { value: <CheckmarkIcon /> },
  { value: <CheckmarkIcon /> },
  { value: <CheckmarkIcon /> },
  { value: <CheckmarkIcon /> },
  { value: <CheckmarkIcon /> },
  { value: <NoCheckmarkIcon />, hideMobile: true },
  { value: <NoCheckmarkIcon />, hideMobile: true },
]

export const KLIPS_BUSINESS_TEAMPLUS = [
  { value: "40" },
  { value: "Unlimited" },
  { value: "Up To The Minute" },
  { value: "130+" },
  { value: <CheckmarkIcon /> },
  { value: <CheckmarkIcon /> },
  { value: <CheckmarkIcon /> },
  { value: <CheckmarkIcon /> },
  { value: <CheckmarkIcon /> },
  { value: <CheckmarkIcon /> },
  { value: <CheckmarkIcon /> },
  { value: <CheckmarkIcon /> },
  { value: <CheckmarkIcon /> },
  { value: <CheckmarkIcon /> },
]
